
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


.login-form {
  width: 600px;
  height: 400px;
  border: 2px solid maroon;
}